import React, { useState } from "react";
import styles from "./css/MainForm.module.scss";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";

const MainForm = () => {
  const [input, setInput] = useState({
    data1: "",
    data17: "",
    ord_cd: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const history = useHistory();

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      history.push(
        `/introduction/8/?data1=${data1}&data17=${data17}&ord_cd=${ord_cd}`
      );
    }
  };

  const { data1, data17, ord_cd } = input;

  return (
    <div className={styles.body_forms}>
      <div className={classNames(styles.forms, styles.login)}>
        <div className={styles.forms_sticky}>
          <div className={styles.forms_title}>검사결과조회</div>
          <div className={styles.input_wrapper}>
            <a
              href="http://web.onway-lab.org/"
              target="blank"
              className={styles.input_btn}
            >
              SEARCH
            </a>
          </div>
        </div>
      </div>
      <div className={classNames(styles.forms, styles.search)}>
        <div className={styles.forms_sticky}>
          <div className={styles.forms_title}>검사항목조회</div>
          <div className={styles.input_wrapper}>
            <div className={styles.input_sticky}>
              <input
                type="text"
                className={styles.input}
                value={data1}
                name="data1"
                onChange={onChange}
                placeholder="검사명"
                onKeyPress={onKeyPress}
              />
            </div>
            <Link
              to={`/introduction/8/?data1=${data1}&data17=${data17}&ord_cd=${ord_cd}`}
              className={styles.input_btn}
            >
              SEARCH
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.bottom_buttons_wrapper}>
        <a
          href="https://939.co.kr/swcl/"
          target="blank"
          className={styles.bottom_button}
        >
          <img
            className={styles.button_left_icon}
            alt=""
            src="../imgs/img/tech-sup.svg"
          />
          <div className={styles.button_text}>원격지원</div>
        </a>
        <a
          target="blank"
          href="https://shinwon-ebook25.s3.ap-northeast-2.amazonaws.com/content/index.html"
          className={styles.bottom_button}
        >
          <img
            className={styles.button_left_icon}
            alt=""
            src={require("./css/book.png")}
          />
          <div className={styles.button_text}>
            2025
            <br />
            검사안내책자
          </div>
        </a>
      </div>
    </div>
  );
};

export default MainForm;
