import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "./css/IrbIntro.module.scss";
import SectionTitle from "../../../components/SectionTitle";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

const IrbIntro = (props) => {
  const [num, setNum] = useState(1);

  const refEl1 = useRef(null);
  const refEl2 = useRef(null);
  const refEl3 = useRef(null);

  const scrollEl = useRef(null);

  const history = useHistory();

  const observerCallback = useCallback((entries) => {
    if (entries[0].isIntersecting) {
      setNum(Number(entries[0].target.id));
    }
  }, []);

  useEffect(() => {
    /*const observer = new IntersectionObserver(observerCallback, {
      root: scrollEl.current,
      rootMargin: "20px",
      threshold: 0.5,
    });

    observer.observe(refEl1.current);
    observer.observe(refEl2.current);
    observer.observe(refEl3.current);

    return () => {
      observer.disconnect();
    };*/
  }, [observerCallback]);

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <SectionTitle kor="임상시험심사위원회" eng="IRB" />
        <div className={styles.title_normal} id="1">
          의료법인 신원의료재단의 임상시험심사위원회 안내입니다.
        </div>
        <div className={styles.section}>
          <div className={styles.separation_wrapper}>
            <a
              href="#1"
              onClick={() => setNum(1)}
              className={
                window.location.toString().split("#")[1] === "1"
                  ? styles.separation_selected
                  : styles.separation_content
              }
            >
              IRB 정의
            </a>
            <a
              href="#2"
              onClick={() => setNum(2)}
              className={
                window.location.toString().split("#")[1] === "2"
                  ? styles.separation_selected
                  : styles.separation_content
              }
            >
              심사대상
            </a>
            <a
              href="#3"
              onClick={() => setNum(3)}
              className={
                window.location.toString().split("#")[1] === "3"
                  ? styles.separation_selected
                  : styles.separation_content
              }
            >
              심사흐름도
            </a>
          </div>
        </div>
        <div className={styles.content_container}>
          <div className={styles.content_section} ref={refEl1}>
            <div className={styles.title}>IRB의 정의</div>
            <div className={styles.content}>
              IRB (Institutional Review Board, 임상시험심사위원회)는 본 기관에서
              진행되는 임상시험에 대해 윤리적, 과학적 타당성을 심사하여 참여
              대상자의 권리, 안전, 복지를 보호하고 시험자가 임상시험을 적절히
              수행할 수 있도록 지원하기 위한 독립 의결 기구입니다.
            </div>
            <div className={styles.irb_define_wrapper} id="2">
              <img
                className={styles.irb_define}
                src={require("../../../assets/imgs/irb_define.png")}
              />
            </div>
          </div>
        </div>

        <div className={styles.content_container}>
          <div className={styles.content_section} ref={refEl2}>
            <div className={styles.title}>IRB 심사 대상</div>
            <div className={styles.irb_target_container}>
              <div className={styles.irb_target_item}>
                <div className={styles.irb_target_item_key}>
                  임상적 성능시험
                </div>
                <div className={styles.irb_target_item_value}>
                  체외진단의료기기의 성능을 증명하기 위하여 검체를 분석하여
                  임상적, 생리적, 병리학적 상태와 관련된 결과를 확인하는 시험을
                  말합니다.
                </div>
              </div>

              <div className={styles.irb_target_item}>
                <div className={styles.irb_target_item_key}>
                  인체유래물 연구
                </div>
                <div className={styles.irb_target_item_value}>
                  인체로부터 수집하거나 채취한 조직•세포•혈액•체액 등 인체
                  구성물 또는 이들로부터 분리된 혈청, 혈장, 염색체, DNA, RNA,
                  단백질 등을(연구자) 직접 조사•분석하여 수행하는 연구를
                  말합니다.
                </div>
              </div>

              <div className={styles.irb_target_item} id="3">
                <div className={styles.irb_target_item_key}>인간대상 연구</div>
                <div className={styles.irb_target_item_value}>
                  사람을 대상으로 물리적으로 개입하거나 의사소통, 대인접촉 등의
                  상호작용을 통하여 수행하는 연구, 또는 개인을 식별할 수 있는
                  정보를 이용하는 연구로서 보건복지부령으로 정하는 연구를
                  말합니다.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.content_container}>
          <div className={styles.content_section} ref={refEl3}>
            <div className={styles.title}>IRB 심사 흐름도</div>
            <div className={styles.irb_flow_wrapper}>
              <img
                className={styles.irb_flow}
                src={require("../../../assets/imgs/irb_flow.png")}
              />
            </div>
          </div>
        </div>
        <div className={styles.shinwon_irb_wrapper}>
          <img
            src={require("../../../assets/imgs/shinwon_irb.png")}
            className={styles.shinwon_irb}
          />
        </div>
      </div>
    </div>
  );
};

export default IrbIntro;
