import React, { useState, useEffect } from "react";
import styles from "./css/Official.module.scss";
import SectionTitle from "../../../components/SectionTitle";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import queryString from "query-string";
import { ReactComponent as DownloadIcon } from "./css/img/icon-download.svg";

const Official = ({ props }) => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    Number(queryString.parse(props.search).page) || 1
  );
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [q, setQ] = useState("");

  const handleSearch = () => {
    setQ(search);
  };

  const history = useHistory();

  const getPageNumber = () => {
    var pageNum = 0;
    if (count % 20 !== 0) pageNum = count / 20 + 1;
    else if (count % 20 === 0) pageNum = count / 20;
    return pageNum;
  };

  const pageNumber = [];
  for (let i = 1; i <= getPageNumber(); i++) {
    pageNumber.push(i);
  }

  const PageNumContent = ({ num }) => {
    return (
      <span
        className={
          num === currentPage ? styles.pagenum_selected : styles.pagenum_wrapper
        }
        onClick={() => paginate(num)}
      >
        <div className={styles.pagenum}>{num}</div>
      </span>
    );
  };

  const pageNumberList = pageNumber
    .filter((num) => {
      if (currentPage >= 10) {
        return currentPage - 8 <= num && num <= currentPage + 1;
      } else {
        return num <= 10;
      }
    })
    .map((num) => <PageNumContent key={num} num={num} />);
  const paginate = (number) => {
    setCurrentPage(number);
    history.push(`/service/1/?page=${number}`);
  };

  const OfficialContent = ({
    id,
    title,
    date,
    url,
    fileurl,
    fileurl2,
    fileurl3,
  }) => {
    return (
      <Link
        to={`/service/1/${url.split("/")[4]}`}
        className={styles.official_content}
      >
        <div className={styles.official_id}>{id}</div>
        <div className={styles.official_title}>{title}</div>
        <div className={styles.official_date}>{date.split(" ")[0]}</div>
        <a
          className={styles.official_top_file}
          href={fileurl || fileurl2 || fileurl3}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          {(fileurl || fileurl2 || fileurl3) && (
            <DownloadIcon className="forms-btn-icon" />
          )}
        </a>
      </Link>
    );
  };

  const currentPostsList = posts.map((post, index) => (
    <OfficialContent
      id={post.pk}
      title={post.title}
      date={post.regdate}
      key={index}
      url={post.url}
      fileurl={post.fileurl}
      fileurl2={post.fileurl2}
      fileurl3={post.fileurl3}
    />
  ));

  useEffect(() => {
    axios
      .get(
        `https://api.shinwon.org/board/?b_idx=1&page=${currentPage}&search=${q}`
      )
      .then((res) => {
        setPosts(res.data["results"]);
        setCount(res.data["count"]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentPage, q]);

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <SectionTitle kor="공문" eng="Official" />
        <div className={styles.title_normal}>
          의료법인 신원의료재단의 대내외 공문을 확인 할 수 있습니다.
        </div>
        <div className={styles.searchContainer}>
          <div className={styles.searchTitle}>공문검색</div>
          <input
            type="text"
            placeholder="검색어를 입력하세요"
            onChange={(e) => setSearch(e.target.value)}
            className={styles.searchInput}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <button onClick={handleSearch} className={styles.searchButton}>
            조회
          </button>
        </div>
        <div className={styles.official_content + " " + styles.official_top}>
          <div className={styles.official_id}>No</div>
          <div className={styles.official_title}>제목</div>
          <div className={styles.official_top_date}>작성일</div>
          <div className={styles.official_top_file}>첨부</div>
        </div>
        {currentPostsList.length === 0 && (
          <div className={styles.no_data}>데이터가 없습니다.</div>
        )}
        {currentPostsList}
        <div className={styles.pagenumberlist}>
          <div
            className={styles.previous}
            onClick={() => setCurrentPage(currentPage - 1)}
          />
          {pageNumberList}
          <div
            className={styles.next}
            onClick={() => setCurrentPage(currentPage + 1)}
          />
        </div>
      </div>
    </div>
  );
};

export default Official;
