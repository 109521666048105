import React, { useState } from "react";
import styles from "./css/MissionValue.module.scss";
import classNames from "classnames";
import AOS from "aos";
import "aos/dist/aos.css";

const MissionValue = () => {
  AOS.init();

  const [num, setNum] = useState(1);

  const Content1 = () => {
    return (
      <div className={styles.section}>
        <div
          className={styles.section_box}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <span className={styles.section_box_title_sub}>Mission</span>
          <span className={styles.section_box_title}>미션</span>
          <div className={styles.section_box_content}>
            질 높은 검사 서비스를 제공하여 <b>인류의 건강하고 행복한 삶</b>에
            기여한다.
          </div>
        </div>

        <div
          className={styles.section_box}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <span className={styles.section_box_title_sub}>Vision</span>
          <span className={styles.section_box_title}>비전</span>
          <div className={styles.section_box_content}>
            끊임없는 <b>사업역량을</b> 확보하고, <b>차별화된 의료서비스 제공</b>
            을 통해 <b>고객만족</b>을 실현하는 <b>전문기관</b>
          </div>
          <ul>
            <li>
              내부적으로는 안정적인 품질관리체계를 구축하고 지속적인 개선 활동을
              통해 발전을 추구합니다.
            </li>
            <li>
              고객에게는 차별화된 의료검사 서비스를 제공하여 인류 건강 증진에
              기여하는 전문기관이 되겠습니다.
            </li>
          </ul>
        </div>

        <div
          className={styles.primary_task_box}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className={styles.section_box_title_2}>주요 추진과제</div>
        </div>

        <div
          className={styles.primary_task_img_box}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <img
            className={styles.primary_task_img}
            src={require("../../../assets/imgs/primary_task.png")}
          />
        </div>

        <div
          className={styles.core_value_box}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className={styles.section_box_title_2}>
            핵심가치 : Core Value
          </div>
        </div>

        <div
          className={styles.core_value_img_box}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <img
            className={styles.core_value_img}
            src={require("../../../assets/imgs/core_value.png")}
          />
        </div>
      </div>
    );
  };

  const Content2 = () => {
    return (
      <div className={styles.section}>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.identity_title}
        >
          CI (Identity) / SYMBOL
        </div>
        <div className={styles.identity_wrapper}>
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            className={styles.identity_content_top}
          >
            <div className={styles.identity_subtitle}>
              <div className={styles.rect} />
              <div className={styles.subtitle_text}>국문</div>
            </div>
            <div className={styles.identity_img_wrapper}>
              <img
                src="../imgs/img/identity2.svg"
                alt=""
                className={styles.identity_img}
              />
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            className={styles.identity_content_bot}
          >
            <div className={styles.identity_symbol_wrapper}>
              <div className={styles.identity_subtitle}>
                <div className={styles.rect} />
                <div className={styles.subtitle_text}>SYMBOL</div>
              </div>
              <div className={styles.symbol_img_wrapper}>
                <img
                  src="../imgs/img/identity4.svg"
                  alt=""
                  className={styles.symbol_img}
                />
              </div>
            </div>
            <div className={styles.identity_color_wrapper}>
              <div className={styles.color_wrapper}>
                <div
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className={styles.identity_subtitle}
                >
                  <div className={styles.rect} />
                  <div className={styles.subtitle_text}>COLOR</div>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className={styles.color_contents}
                >
                  <div className={styles.color_content} />
                  <div className={styles.color_content} />
                  <div className={styles.color_content} />
                  <div className={styles.color_content} />
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="2000"
                className={styles.download_wrapper}
              >
                <div className={styles.identity_subtitle}>
                  <div className={styles.rect} />
                  <div className={styles.subtitle_text}>CI DOWNLOAD</div>
                </div>
                <a
                  href="https://api.shinwon.org/media/shinwon-CI.zip"
                  download
                  className={styles.download_sticky}
                >
                  신원의료재단 로고 다운로드
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.mission_top_wrapper}>
        <div
          onClick={() => setNum(1)}
          className={
            num === 1
              ? classNames(
                  styles.mission_top_content,
                  styles.mission_top_selected
                )
              : styles.mission_top_content
          }
        >
          미션/비전
        </div>
        <div
          onClick={() => setNum(2)}
          className={
            num === 2
              ? classNames(
                  styles.mission_top_content,
                  styles.mission_top_selected
                )
              : styles.mission_top_content
          }
        >
          IDENTITY
        </div>
      </div>
      <div className={styles.greeting_top_wrapper}>
        <img
          src="../imgs/img/greeting-top.png"
          alt=""
          className={styles.greeting_top_img}
        />
      </div>
      {num === 1 ? <Content1 /> : <Content2 />}
    </div>
  );
};

export default MissionValue;
